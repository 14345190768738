<template>
    <b-overlay :show="loading">
    <b-card>
        <b-form @submit.prevent>
            <app-collapse>
                <app-collapse-item title="Búsqueda">
                    <b-row>
                        <b-col md="3">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Fecha desde
                                </h5>
                                <div class="input-group">
                                    <flat-pickr
                                            v-model="filter.fechaDesde"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="config"
                                            reset-button
                                    />
                                    <div class="input-group-append">
                                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                            <feather-icon
                                                    icon="XIcon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Fecha Hasta
                                </h5>
                                <div class="input-group">
                                    <flat-pickr
                                            v-model="filter.fechaHasta"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="config"
                                            reset-button
                                    />
                                    <div class="input-group-append">
                                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                            <feather-icon
                                                    icon="XIcon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Ficha
                                </h5>
                                <b-form-input
                                        id="ficha"
                                        v-model="filter.ficha"
                                        placeholder="N° de Ficha"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Holding
                                </h5>
                                <div class="form-label-group">
                                    <autocomplete-infinity
                                            url="entidades/holding"
                                            placeholder="Seleccione Holding"
                                            @item-selected="selectHolding"
                                            label="nombre_holding"
                                            id="holding"
                                            itemsPerPage="15"
                                            :params="getFiltersHoldings()"
                                    ></autocomplete-infinity>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Clientes
                                </h5>
                                <div class="form-label-group">
                                    <autocomplete-infinity
                                            url="entidades/empresa"
                                            placeholder="Seleccione Cliente"
                                            @item-selected="selectClientes"
                                            label="razon_social_empresa"
                                            id="empresa"
                                            itemsPerPage="15"
                                            :params="getFiltersEmpresas()"
                                    ></autocomplete-infinity>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Cursos
                                </h5>
                                <div class="form-label-group">
                                    <b-form-group>
                                        <autocomplete-infinity
                                                url="entidades/course-version-without-presencial-sychronous-modality"
                                                placeholder="Seleccione Curso"
                                                @item-selected="selectAplicaciones"
                                                label="description"
                                                itemsPerPage="15"
                                                id="curso"
                                                :params="getFiltersCourses()"
                                        ></autocomplete-infinity>

                                    </b-form-group>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <div class="text-right mt-2">
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        @click="getList">

                                    <feather-icon
                                            icon="SearchIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Buscar</span>
                                </b-button>
                                <b-button class="d-none"
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-primary"
                                          type="submit">

                                    <feather-icon
                                            icon="RefreshCcwIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Limpiar</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </app-collapse-item>
            </app-collapse>
        </b-form>
    </b-card>
        <b-card>
            <h4 class="card-title">Listado de Fichas E-Learning            </h4>
            <b-row class="pr-1 pl-1">
                <b-col sm="4" md="4" class="my-1 text-right">
                    <div class="d-flex align-items-center mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <b-form-select
                                @change="onChangePerPage"
                                id="perPageSelect"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                                class="mx-50 col-md-3"
                        ></b-form-select>
                        <label>filas por página</label>
                    </div>
                </b-col>
                <b-col cols="4" class="mt-2">
                    <div v-if="totalRows > 0">
                        <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                    </div>
                </b-col>

                <b-col cols="4">
                    <b-pagination
                            @change="onPageChange"
                            :total-rows="totalRows"
                            :value="page"
                            :per-page="perPage"
                            align="right"
                            class="mt-2 mr-1"
                            aria-controls="my-table"
                    />
                </b-col>
            </b-row>
            <div class="d-none"></div>
            <b-col cols="12" >
                <b-table
                        striped
                        hover
                        selectable
                        small
                        :per-page="perPage"
                        :items="listado"
                        :fields="fields"
                        responsive
                        class="mb-0"
                        id="my-table"
                        ref="table"
                        show-empty
                        select-mode="multi"
                >

                    <template #cell()="data">
                        {{ data.value ? data.value : '-' }}
                    </template>
                    <template #cell(fecha_inicio)="data">
                        <b-badge variant="success">
                            {{ data.item.fecha_inicio  ? data.item.fecha_inicio : '-'}}
                        </b-badge>
                    </template>
                    <template #cell(fecha_cierre)="data">
                        <b-badge variant="success">
                            {{ data.item.fecha_cierre  ? data.item.fecha_cierre : '-'}}
                        </b-badge>
                    </template>
                    <template #cell(detalle)="data">
                        <!-- Column: Action -->
                        <div class="text-nowrap">
                            <feather-icon
                                    v-b-tooltip.hover.top.v-primary="'Ver Detalle'"
                                    icon="EyeIcon"
                                    class="mr-1"
                                    @click="detalleFicha(data.item)"


                            />
                        </div>
                    </template>
                    <template #empty="scope">
                        <div class="text-center">No existen resultados</div>
                    </template>
                </b-table>
            </b-col>
        </b-card>
        <b-modal
                id="modal-alumno"
                ref="modal_alumno"
                :title="'Listado de Alumnos registrado en la ficha No. ' + noFicha"
                ok-title="Cerrar"
                size="xl"
                ok-only
        >
            <listado-alumno :ficha="noFicha"></listado-alumno>
        </b-modal>
    </b-overlay>
</template>

<script>
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker, BOverlay,
        BTable,
        BProgress,
        BBadge,
        BPagination, VBTooltip,
        BModal

    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import vSelect from 'vue-select'
    import ListadoAlumno from '@/views/infoFicha/ListadoAlumno.vue'
    export default {
        name: "ListadoFicha",
        components: {
            AppCollapse,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormRadio,
            flatPickr,
            AppCollapseItem,
            BFormDatepicker,
            vSelect,
            BOverlay,
            BTable,
            BProgress,
            BBadge,
            BPagination,
            'listado-alumno': ListadoAlumno,
            BModal

        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        data() {
            return {
                perPage: 50,
                pageOptions: [50, 100, 500, 1000],
                page: 1,
                fields: [
                    {key: "num_ficha", label: "No. Ficha"},
                    {key: "tbl_course_version_complete", label: "curso"},
                    {key: "razon_social_empresa", label: "Empresa"},
                    {key: "nombre_holding", label: "Holding"},
                    {key: "fecha_inicio", label: "Fecha Inicio"},
                    {key: "fecha_cierre", label: "Fecha Término"},
                    {key: "cant_alumno", label: "Cant. de Alumnos", tdClass: 'text-center', thClass: 'text-center'},
                    {key: "detalle", label: "Detalle", tdClass: 'text-center', thClass: 'text-center'},
                ],
                list: [
                    {"id_ficha":"10699","num_ficha":"26951","fecha_inicio":"2020-02-05","fecha_cierre":"2020-04-30",
                        "nombre_empresa":"77682510-7 | Linares Y Cia Ltda.","nombre_holding":"Edutecno",
                        "nombre_curso":"Herramientas De Comunicaci\u00f3n Efectiva Y Trabajo En Equipo E-Learning Generico 50h V1(id:60) | Campus Edutecno 3.7","id_orden_compra":"14807","cantidad_alumnos":"1","id_platform_version":"5","id_course_in_platform":"60"}
                ],
                filter: {
                    fechaDesde: null,
                    fechaHasta: null,
                    type: 3,
                    ficha: null,
                    ordenCompra: null,
                    aplicacion: null,
                    holding: null,
                    ejecutivo: null,
                    cliente: null,
                },
                noFicha: null,
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            getList() {
                let filterData = {
                    'page': this.page,
                    'perPage': this.perPage,
                    'type': 3,
                    'fecha_desde': this.filter.fechaDesde,
                    'fecha_hasta': this.filter.fechaHasta,
                    'num_ficha': this.filter.ficha,
                    'id_aplicacion': this.filter.aplicacion,
                    'id_empresa': this.filter.cliente,
                    'id_holding': this.filter.holding,
                }
                this.$store.dispatch('infoFicha/getListFicha', filterData)
            },
            detalleFicha(data){
                this.noFicha = data.num_ficha
                this.$refs.modal_alumno.show();
                this.$store.commit('infoFicha/setEstado', data.estado_ficha)
            },
            onPageChange(page) {
                this.page = page;
                this.getList();
            },
            onChangePerPage() {
                this.page = 1;
                this.getList();
            },
            getFiltersEjecutivos(){
                return {
                    "empresa" :this.filter.empresa,
                    "holding" :this.filter.holding,
                    "curso" :this.filter.aplicacion,
                }
            },
            getFiltersHoldings(){
                return {
                    "empresa" :this.filter.empresa,
                    "ejecutivo" :this.filter.ejecutivo,
                    "curso" :this.filter.aplicacion,
                }
            },
            getFiltersCourses(){
                return {
                    "empresa" :this.filter.empresa,
                    "ejecutivo" :this.filter.ejecutivo,
                    "holding" :this.filter.holding,
                }
            },
            getFiltersEmpresas(){
                return {
                    "holding" :this.filter.holding,
                    "ejecutivo" :this.filter.ejecutivo,
                    "curso" :this.filter.aplicacion,
                }
            },
            selectHolding(holding) {
                this.filter.holding = holding != null ? holding.id_holding : null;
            },
            selectClientes(clientes) {
                this.filter.cliente = clientes != null ? clientes.id_empresa : null;
            },
            selectEjecutivos(ejecutivos) {
                this.filter.ejecutivo = ejecutivos != null ? ejecutivos.id_usuario : null;
            },
            selectAplicaciones(aplicaciones) {
                this.filter.aplicacion = aplicaciones != null ? aplicaciones.id : null;
            },

        },
        computed: {
            config() {
                return {
                    locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
                    dateFormat: "d-m-Y",
                    wrap: true,
                }
            },
            totalRows() {
                return this.$store.state.infoFicha.rowsTotal;
            },
            to() {
                return this.$store.state.infoFicha.to;
            },
            from() {
                return this.$store.state.infoFicha.from;
            },
            listado() {
                return this.$store.state.infoFicha.list
            },
            loading() {
                return this.$store.state.infoFicha.loading;
            },
        }
    }
</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .demo-inline-spacing.curso .custom-radio {
        margin-top: 0.5rem !important;
    }

    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
</style>
