<template>
    <b-overlay :show="loading">
    <b-card>
        <b-row class="pr-1 pl-1">
            <b-col md="12" >
                <h5 class="font-weight-bold">
                    <feather-icon
                            icon="InfoIcon"
                    />
                    Empresa: {{listado.length > 0 ? listado[0].razon_social_empresa +' '+ listado[0].rut_empresa : '-'}}
                </h5>
            </b-col>
            <b-col  offset-md="7" md="5" >
                <b-form-group
                >
                    <h5 class="font-weight-bold">
                        Rut / Nombre / Apellidos
                    </h5>
                    <b-form-input
                            id="ficha"
                            v-model="alumno"
                            placeholder="Escriba el dato del Alumno a buscar"
                            @input="onFiltered"
                    />
                </b-form-group>
            </b-col>
            <b-col sm="4" md="4" class="my-1 text-right">
                <div class="d-flex align-items-center mb-1 mb-md-0">
                    <label>Mostrar</label>
                    <b-form-select
                            @change="onChangePerPage"
                            id="perPageSelect"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            class="mx-50 col-md-3"
                    ></b-form-select>
                    <label>filas por página</label>
                </div>
            </b-col>
            <b-col cols="4" class="mt-2">
                <div v-if="totalRows > 0">
                    <span class="text-muted">  Mostrando del {{from}} al {{to}} de {{totalRows}} filas</span>
                </div>
            </b-col>

            <b-col cols="4">
                <b-pagination
                        @change="onPageChange"
                        :total-rows="totalRows"
                        :value="page"
                        :per-page="perPage"
                        align="right"
                        class="mt-2 mr-1"
                        aria-controls="my-table"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                        striped
                        hover
                        selectable
                        small
                        :per-page="perPage"
                        :items="listado"
                        :fields="fields"
                        responsive
                        class="mb-0"
                        id="my-table"
                        ref="table"
                        show-empty
                        select-mode="multi"
                >

                    <template #cell()="data">
                        {{ data.value ? data.value : '-' }}
                    </template>
                    <template #cell(porciento_avance)="data">
                        <div class="text-center">
                            <b-badge class="center" :variant="porciento_avance(data.item.porciento_avance)">{{ data.item.porciento_avance  ? (data.item.porciento_avance +'%') : '-'}}</b-badge>
                        </div>
                    </template>
                    <template #cell(detalle)="data">
                        <!-- Column: Action -->
                        <div class="text-nowrap">
                            <feather-icon
                                    icon="EyeIcon"
                                    class="mr-1"
                                    @click="detalleAlumno(data.item)"


                            />
                        </div>
                    </template>
                    <template #empty="scope">
                        <div class="text-center">No existen resultados</div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-modal
                id="modal-alumno"
                ref="modal_alumno"
                :title="'Detalles del Alumno: ' + nombreAlumno"
                ok-title="Cerrar"
                size="xl"
                ok-only
        >
            <detalle-alumno :alumno="idDetalleAlumno"></detalle-alumno>
        </b-modal>
    </b-card>
    </b-overlay>
</template>

<script>
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker, BOverlay,
        BTable,
        BProgress,
        BBadge,
        BPagination, VBTooltip, BModal,

    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import vSelect from 'vue-select'
    import DetalleAlumno from '@/views/infoFicha/DetalleAlumno.vue'

    export default {
        name: "ListadoAlumno",
        components: {
            AppCollapse,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormRadio,
            flatPickr,
            AppCollapseItem,
            BFormDatepicker,
            vSelect,
            BOverlay,
            BTable,
            BProgress,
            BBadge,
            BPagination,
            'detalle-alumno': DetalleAlumno,
            BModal
        },
        props: ['ficha'],
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
        },
        data() {
            return {
                perPage: 50,
                pageOptions: [50, 100, 500, 1000],
                page: 1,
                fields: [
                    //{key: "nombre_empresa", label: "Empresa"},
                    {key: "rut", label: "RUT"},
                    {key: "nombre", label: "Nombre"},
                    {key: "apellidos", label: "Apellidos"},
                    {key: "descripcion_centro_costo", label: "Costo"},
                    {key: "porciento_avance", label: "% Avance", tdClass: 'text-center', thClass: 'text-center'},
                    {key: "evaluacion_final_nota", label: "Nota E. Final", tdClass: 'text-center', thClass: 'text-center'},
                    {key: "estado_inscripcion", label: "Situación"},
                    {key: "num_orden_compra", label: "O.C"},
                    {key: "detalle", label: "Detalle", tdClass: 'text-center', thClass: 'text-center'},
                ],
                list: [
                    {
                        "nombre_empresa": "Caja De Compensacion De Asignacion Familiar De Los Andes | 81826800-9",
                        "rut_alumno": "17251194-5", "nombre_alumno": "Catalina", "apellidos": "Stuardo Arenas",
                        "costo": "Becado", "situacion": "Inscrito", "numero_orden_compra": "sin orden de compra10446",
                        "id_detalle_alumno": "612983"
                    }
                ],
                alumno: null,
                nombreAlumno:null,
                idDetalleAlumno: null,
            }
        },
        mounted() {
            this.getList();
        },
        methods: {
            getList() {
                let filterData = {
                    'page': this.page,
                    'item_per_page': this.perPage,
                    'num_ficha': this.ficha,
                    'alumno': this.alumno,
                }
                this.$store.commit('infoFicha/setListAlumno', [])
                this.$store.dispatch('infoFicha/getListAlumno', filterData)
            },
            porciento_avance(value) {
                const number = parseFloat(value)
                if (number < 50) {
                    return 'danger'
                } else if (number >= 50 && number < 100) {
                    return 'warning'
                } else if (number >= 100) {
                    return 'success'
                }
            },
            detalleAlumno(data){
                this.nombreAlumno = data.nombre + ' ' +data.apellidos
                this.idDetalleAlumno = data.id_detalle_alumno
                this.$refs.modal_alumno.show();
            },
            onFiltered:_.debounce(function (filter) {
                this.getList()
            }, 800),
            onPageChange(page) {
                this.page = page;
                this.getList();
            },
            onChangePerPage() {
                this.page = 1;
                this.getList();
            },
            getFiltersEjecutivos(){
                return {
                    "empresa" :this.filter.empresa,
                    "holding" :this.filter.holding,
                    "curso" :this.filter.aplicacion,
                }
            },
            getFiltersHoldings(){
                return {
                    "empresa" :this.filter.empresa,
                    "ejecutivo" :this.filter.ejecutivo,
                    "curso" :this.filter.aplicacion,
                }
            },
            getFiltersCourses(){
                return {
                    "empresa" :this.filter.empresa,
                    "ejecutivo" :this.filter.ejecutivo,
                    "holding" :this.filter.holding,
                }
            },
            getFiltersEmpresas(){
                return {
                    "holding" :this.filter.holding,
                    "ejecutivo" :this.filter.ejecutivo,
                    "curso" :this.filter.aplicacion,
                }
            },
            selectHolding(holding) {
                this.filter.holding = holding != null ? holding.id_holding : null;
            },
            selectClientes(clientes) {
                this.filter.cliente = clientes != null ? clientes.id_empresa : null;
            },
            selectEjecutivos(ejecutivos) {
                this.filter.ejecutivo = ejecutivos != null ? ejecutivos.id_usuario : null;
            },
            selectAplicaciones(aplicaciones) {
                this.filter.aplicacion = aplicaciones != null ? aplicaciones.id : null;
            },
            selectTutor(tutor) {
                this.filter.tutor = tutor != null ? tutor.codigo : null;
            },

        },
        computed: {
            totalRows() {
                return this.$store.state.infoFicha.rowsTotalAlumno;
            },
            to() {
                return this.$store.state.infoFicha.toAlumno;
            },
            from() {
                return this.$store.state.infoFicha.fromAlumno;
            },
            listado() {
                return this.$store.state.infoFicha.listAlumno
            },
            loading() {
                return this.$store.state.infoFicha.loadingAlumnos;
            },
        }
    }
</script>

<style scoped>

</style>
