<template>
    <b-overlay :show="loading">
<b-card>
    <b-row>
        <b-col md="12">
            <div class="text-right mb-2">
                <b-button v-if="activo"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                        @click="updateDataAlumno"
                >

                    <feather-icon
                            icon="CheckSquareIcon"
                            class="mr-50"
                    />
                    <span class="align-middle">Actualizar datos</span>
                </b-button>
            </div>
        </b-col>
        <b-col md="4">
            <b-row>
                <b-col md="12">
                    <span>Rut</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.rut != null ? dataAlumno.rut : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Curso</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.tbl_course_version_description != null ? dataAlumno.tbl_course_version_description : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Ficha</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.num_ficha != null ? dataAlumno.num_ficha : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Ejecutivo</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.ejecutivo_empresa_nombrecompleto_usuario != null ? dataAlumno.ejecutivo_empresa_nombrecompleto_usuario : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Costo</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.descripcion_centro_costo != null ? dataAlumno.descripcion_centro_costo : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Tiempo conexión</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.conexion_sence == 'no' ? 'No' : (dataAlumno.conexion_sence == 'si' ? 'Si' : '-') }}</span>
                </b-col>
            </b-row>
        </b-col>
        <b-col md="4">
            <b-row> <b-col md="12">
                <span>Declaraciones Juradas</span>
                <br/>
                <span class="font-weight-bold">{{dataAlumno.declaracion_jurada != null  ? dataAlumno.declaracion_jurada: '-' }}</span>
            </b-col>
                <b-col md="12" class="mt-1">
                    <span>Avance Contenido</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.porciento_avance != null ? (dataAlumno.porciento_avance+'%') : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Primer Ingreso</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.primer_acceso != null ? dataAlumno.primer_acceso : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Último Ingreso</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.ultimo_acceso != null ? dataAlumno.ultimo_acceso : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Encuesta</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.encuesta != null ? dataAlumno.encuesta : '-' }}</span>
                </b-col>
                <b-col md="12" class="mt-1">
                    <span>Evaluaciones Realizadas</span>
                    <br/>
                    <span class="font-weight-bold">{{dataAlumno.porciento_evaluaciones != null ? dataAlumno.porciento_evaluaciones+'%' : '-' }}</span>
                </b-col>
            </b-row>
        </b-col>
        <b-col md="4">
            <b-row>
                <b-col md="12">
                    <b-table :items="dataAlumno.evaluaciones" :fields="fields" show-empty>
                        <template #cell(evaluacion)="data">
                            <small>{{data.item.nombre_evaluacion}}</small>
                        </template>
                        <template #empty="scope">
                            <div class="text-center">No existen resultados</div>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-col>
      <div class="d-none">{{notificaciones}}</div>
    </b-row>
</b-card>
    </b-overlay>
</template>

<script>
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker, BOverlay,
        BTable,
        BProgress,
        BBadge,
        BPagination, VBTooltip, BModal,

    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import store from "@/store";
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
    export default {
        name: "DetalleAlumno",
        components: {
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BOverlay,
            BTable,
            BProgress,
            BBadge,
            BPagination,
        },
      directives: {
        Ripple,
      },
        props: ['alumno'],
        data() {
            return {
                fields: [
                    {key: "nombre_evaluacion", label: "Evaluación"},
                    {key: "nota", label: "Resultado", tdClass: 'text-center', thClass: 'text-center'}],
                items: [
                    { evaluacion: 'Realizar Evaluación Diagnóstica', resultado: '--' },
                ]
            }
        },
        mounted() {
            this.getDetalleAlumno();
        },
        methods: {
            getDetalleAlumno() {
                let filterData = {
                    'id_detalle_alumno': this.alumno,
                }
                this.$store.commit('infoFicha/setAlumno', [])
                this.$store.dispatch('infoFicha/getDetalleAlumno', filterData)
            },
            updateDataAlumno() {
                let filterData = {
                    'id_detalle_alumno': this.alumno,
                }
                this.$store.dispatch('infoFicha/updateDataAlumno', filterData)
            },
          showToast(variant, mensaje) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notificación',
                icon: 'BellIcon',
                text: mensaje,
                variant,
              },
            })
          },
        },
        computed: {
            dataAlumno() {
                return this.$store.state.infoFicha.alumno;
            },
            loading() {
                return this.$store.state.infoFicha.loadingAlumno;
            },
            activo() {
                return this.$store.state.infoFicha.activo;
            },
          notificaciones() {
            let mensaje = this.$store.state.infoFicha.mensaje
            if (mensaje == 'Se actualizó la informacion del alumno.') {
              this.showToast('success',mensaje)
              store.commit('infoFicha/setMensaje', '')
              this.getDetalleAlumno();
            }
            else if (mensaje != ''){
              this.showToast('warning','Hubo un error. No se pudo actualizar la información del alumno')
              store.commit('infoFicha/setMensaje', '')
            }

          },
        }

    }
</script>

<style scoped>

</style>
